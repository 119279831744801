<!-- Start Footer Area -->
<footer class="footer-area">

    <!-- <div class="container copyright-area hidden-web footer-web">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/img/tutramiteya1.png" alt="image">
            </div>

            <div class="col-lg-6 col-md-6">
                <ul>
                    <li><a class="hidden-web" routerLink="/">Términos y Condiciones</a></li>
                </ul>
            </div>
        </div>
    </div> -->

    <div class="copyright-area" style="background-color: #195c7f;">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <img src="assets/img/tutramiteya1.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Términos y Condiciones</a></li>
                    </ul>
                    <br/>
                    <ul class="social-list">
                        <li><a href="https://www.facebook.com/TuTramiteYaBo" target="_blank"><img src="assets/img/facebook.png" style="width: 32px"/></a></li>
                        <li><a href="https://wa.me/59165057676" target="_blank"><img src="assets/img/whatsapp.png" style="width: 32px"/></a></li>
                        <li><a href="https://www.linkedin.com/company/tu-tramite-ya" target="_blank"><img src="assets/img/linkedin.png" style="width: 32px"/></a></li>
                    </ul>
            </div>
            </div>
        </div>
    </div>
    <div style="text-align: center;padding: 1em;">
        © 2023 TuTramiteya todos los derechos reservados. 
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->