<!-- Start App Main Banner -->
<div class="main-banner app-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container desktopmargin">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>Resuelve todas tus gestiones <br>y trámites más fácil y rápido.</h1>
                            <p>Nosotros lo hacemos por ti.</p>
                            <!-- <form>
                                <input type="email" placeholder="Enter your email...." class="form-control">
                                <button type="submit" class="btn btn-primary">Begin Free Trial</button>
                            </form> -->
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12 reloj-responsive">
                        <div class="foto-reloj">
                            <img src="assets/img/reloj.png" alt="image">
                            <div class="shape-reloj"><img src="assets/img/shape4.png" alt="img"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="bg-gray shape-1"></div> -->
    <!-- <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div> -->
</div>
<!-- End App Main Banner -->

<!-- <app-partner></app-partner> -->

<!-- Start Features Area -->
<section id="servicios" class="features-area app-features ptb-100 second-banner">
    <div class="second-banner2">
    <div class="container desktopmargin2">
        <div class="row mb-0 mt-4">
            <div class="col-lg-12 col-md-12">
                <div class="hero-content wrapper">
                    <i class="icofont-clip" style="font-size: xx-large; margin-top: 20px;"></i>
                    <span class="text">¿Qué trámite necesitas? 
                          <br><p style="margin: 0px;color: white; font-size: 16px; font-weight: normal;">Selecciona una categoria.</p></span>
                    </div>
            </div>
            <div class="col-lg-12 col-md-12" style="padding-bottom: 10px;">
                <div class="tab">
                    <ul class="tabs">
                        <li [ngClass]="{'current': currentTab == 'inmuebles'}"><a (click)="currentTab = 'inmuebles'">
                            <!-- <i class="icofont-home"></i> -->
                            <i>
                                <img src="assets/img/icon-inmuebles.png" style="height: 32px"/>
                                <img src="assets/img/icon-current-inmuebles.png" style="height: 32px"/>
                            </i>
                            <br>
                            Bienes Inmuebles
                        </a></li>
                        
                        <li [ngClass]="{'current': currentTab == 'vehiculos'}"><a (click)="currentTab = 'vehiculos'">
                            <!-- <i class="icofont-car"></i> -->
                            <i>
                                <img src="assets/img/icon-vehiculos.png" style="height: 32px"/>
                                <img src="assets/img/icon-current-vehiculos.png" style="height: 32px"/>
                            </i>
                            <br>
                            Vehículos
                        </a></li>
                        
                        <!-- <li [ngClass]="{'current': currentTab == 'contratos'}"><a (click)="currentTab = 'contratos'"> -->
                            <!-- <i class="icofont-papers"></i> -->
                            <!-- <i>
                                <img src="assets/img/icon-contratos.png" style="height: 32px"/>
                                <img src="assets/img/icon-current-contratos.png" style="height: 32px"/>
                            </i>
                            <br>
                            Contratos
                        </a></li> -->
                        
                        <li [ngClass]="{'current': currentTab == 'negocios'}"><a (click)="currentTab = 'negocios'">
                            <!-- <i class="icofont-bag-alt"></i> -->
                            <i>
                                <img src="assets/img/icon-negocios.png" style="height: 32px"/>
                                <img src="assets/img/icon-current-negocios.png" style="height: 32px"/>
                            </i>
                            <br>
                            Negocios
                        </a></li>
                        
                    </ul>
    
                    <div class="tab-content">
                        <form [formGroup]="form">
                        <div class="tabs_item" [hidden]="currentTab != 'inmuebles'">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-10 col-md-8">
                                    <div class="tabs_item_content mt-0">
                                        <select class="form-control select-tabs" formControlName="inmueble">
                                            <option selected hidden>Seleccione un trámite</option>
                                            <option *ngFor="let inmueble of inmuebles" value="inmueble.id">{{inmueble.name}}</option>
                                        </select>  
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4">
                                    <div class="tabs_item_content mt-0 btn-responsive">
                                        <button class="btn btn-secondary btn-mt" (click)="sendTramite(this.form.controls.inmueble.value, 'inmuebles')">Ver detalle</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="tabs_item" [hidden]="currentTab != 'vehiculos'">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-10 col-md-8">
                                    <div class="tabs_item_content mt-0">
                                        <select class="form-control select-tabs" formControlName="vehiculo">
                                            <option selected hidden>Seleccione un trámite</option>
                                            <option *ngFor="let vehiculo of vehiculos" value="vehiculo.id">{{vehiculo.name}}</option>
                                        </select>  
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4">
                                    <div class="tabs_item_content mt-0 btn-responsive">
                                        <button class="btn btn-secondary btn-mt" (click)="sendTramite(this.form.controls.vehiculo.value, 'vehiculos')">Ver detalle</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="tabs_item" [hidden]="currentTab != 'contratos'">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-10 col-md-8">
                                    <div class="tabs_item_content mt-0">
                                        <select class="form-control select-tabs" formControlName="contrato">
                                            <option selected hidden>Seleccione un trámite</option>
                                            <option *ngFor="let contrato of contratos" value="contrato.id">{{contrato.name}}</option>
                                        </select>  
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4">
                                    <div class="tabs_item_content mt-0 btn-responsive">
                                        <button class="btn btn-secondary btn-mt" (click)="sendTramite(this.form.controls.contrato.value, 'contratos')">Ver detalle</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item" [hidden]="currentTab != 'negocios'">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-10 col-md-8">
                                    <div class="tabs_item_content mt-0">
                                        <select class="form-control select-tabs" formControlName="negocio">
                                            <option selected hidden>Seleccione un trámite</option>
                                            <option *ngFor="let negocio of negocios" value="negocio.id">{{negocio.name}}</option>
                                        </select>  
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4">
                                    <div class="tabs_item_content mt-0 btn-responsive">
                                        <button class="btn btn-secondary btn-mt" (click)="sendTramite(this.form.controls.negocio.value, 'negocios')">Ver detalle</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-gray shape-1"></div>
    <!-- <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div> -->
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</div>
</section>

<section class="features-area app-features second-feature">
    <div class="features-inner-area desktopmargin3">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12" style="text-align: center;">
                    <div class="features-image">
                        <img src="assets/img/Celular-app.png" class="hidden-responsive img-celu" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">

                        <div class="features-item" style="padding-left: 60px;">
                            <p style="font-weight: bold; font-size: 20px;">Descarga nuestra App <br>Experimeta la diferencia entre un trámite <br><span style="color: #c00d0d; font-weight: bolder;">y un trámiteya</span></p>
                        </div>

                        <div class="downloadapp bar"></div>

                        <div class="pt-4" style="padding-left: 50px;">
                           <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="hero-content wrapper button-store mt-4" onclick="var anchor = document.createElement('a');anchor.href = 'https://play.google.com/store/apps/details?id=com.tutramiteya.app';anchor.target='_blank';anchor.click();">
                                        <!-- <i class="icofont-brand-android" style="font-size: xx-large; margin-top: 15px; color: black;"></i> -->
                                        <img src="assets/img/playstore.png" style="height: 25px; margin-top: 17px;" alt="image">
                                        <span class="text" style="color: #585354; font-size: 14px; margin-bottom: 0px;">Disponible
                                            <br><p style="margin: 0px; color: #585354; font-size: 14px; font-weight: normal;">en Google Playstore</p></span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="hero-content wrapper button-store mt-4" onclick="var anchor = document.createElement('a');anchor.href = 'https://apps.apple.com/bo/app/tutramiteya/id1609791237';anchor.target='_blank';anchor.click();">
                                        <i class="icofont-brand-apple" style="font-size: xx-large; margin-top: 15px; color: black;"></i>
                                        <span class="text" style="color: #585354; font-size: 14px; margin-bottom: 0px;">Disponible
                                            <br><p style="margin: 0px; color: #585354; font-size: 14px; font-weight: normal;">en Apps Store</p></span>
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-gray shape-1"></div>

</section>
<!-- End Features Area -->

<!-- Start How App Work -->
<!-- <section class="how-app-work ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>How App Works</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="app-mobile">
                    <img src="assets/img/app-mobile.png" alt="mobile">
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-app-works">
                    <div class="icon">
                        <i class="icofont-downloaded"></i>
                    </div>
                    <h3>Download</h3>
                    <p>Duis cursus dictum enim dictum venenatis. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-app-works">
                    <div class="icon">
                        <i class="icofont-settings"></i>
                    </div>
                    <h3>Setup It</h3>
                    <p>Duis cursus dictum enim dictum venenatis. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-app-works">
                    <div class="icon">
                        <i class="icofont-thumbs-up"></i>
                    </div>
                    <h3>Enjoy it</h3>
                    <p>Duis cursus dictum enim dictum venenatis. Orci varius natoque penatibus et magnis dis parturient montes.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End How App Work -->

<!-- Start Overview Area -->
<section id="nosotros" class="overview-section ptb-100">
    <div class="container" style="margin-top: 120px;">

        <div class="overview-box">
            <div class="row mb-4 h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content alineacion-contacto">
                    <div>
                        <a routerLink="/about-us" class="btn-nosotros btn-nosotros2 mb-4">Nosotros</a>
                        
                        <div class="title-nosotros">
                            <h3 style="font-size: 28px; color: #f1f5f7;">Todo un equipo </h3><h3 class="pl-2" style="font-size: 28px; color: #f1f5f7;"> de tu lado.</h3>
                        </div>
                        <!-- <img class="hidden-web background-nosotros-resp" src="assets/img/nosotros.png" alt=""> -->
                    </div>
                    <p class="mb-4 nosotros-font margin-nosotros" style="font-size: 18px;">Contamos con el mejor equipo de trabajo con la finalidad de
                        simplificarle su tiempo, realizándole todo tipo de trámites
                        cumpliendo con nuestro trabajo de manera ágil, oportuna y
                        eficiente; con las tarifas más competitivas del mercado, ayudando
                        a nuestros clientes a maximizar su día mientras están en la 
                        tranquilidad de sus hogares o trabajos, haciendo el seguimiento
                        respectivo desde nuestra aplicación, teniendo la confianza que sus
                        trámites están en manos de expertos y profesionales.
                    </p>
                </div>
                
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/nosotros-modelos.png" class="hidden-responsive image-nosotros" alt="image">
                </div>
                
            </div>
            
            <div class="row mt-4">
                <div class="col-lg-3 col-md-6 col-sm-6 mt-4">
                    <p class="button-nosotros"><i class="icofont-law-document mr-4" style="color: black; font-size: xx-large;"></i> Trámites serios</p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 mt-4">
                    <p class="button-nosotros"><i class="icofont-clock-time mr-4" style="color: black; font-size: xx-large;"></i> Tiempos óptimos</p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 mt-4">
                    <p class="button-nosotros"><i class="icofont-dollar mr-4" style="color: black; font-size: xx-large;"></i> Costos accesibles</p>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 mt-4">
                    <p class="button-nosotros"><i class="icofont-computer mr-4" style="color: black; font-size: xx-large;"></i> Respaldo tecnológico</p>
                </div>
                    <!-- <p class="btn btn-primary">Read More</p>
                    <p class="btn btn-primary" style="margin-bottom: 16px;">Read More</p> -->
                <!-- </div> -->
            </div>
        </div>

    </div>
</section>
<!-- End Overview Area -->
<!-- Start Overview Area -->
<section class="ptb-100" style="background-color: #f1f5f7; overflow: hidden;">
    <div class="container">

        <div class="overview-box">
            <div class="row mb-4 h-100 justify-content-center align-items-center">

                <div class="col-lg-6 col-md-6 overview-img padding-compu">
                    <img src="assets/img/compupanel2.png" class="compu-panel hidden-responsive" alt="image">
                </div>
                
                <div class="col-lg-6 col-md-6 overview-content alineacion-contacto" style="padding-left: 20px; padding-right: 20px;">
                    <a class="btn-nosotros mb-4" style="background-color: #195c7f;">Empresas</a>
                    
                    <h3 class="mb-4" style="font-size: 28px; color: #195c7f; font-weight: normal;">¿Grandes volúmenes de trámites? <br>
                        <span style="font-weight: bolder;">Hazlo simple</span></h3>
                        <div class="downloadapp bar barra-empresa"></div>
                        <img src="assets/img/compupanel2.png" class="compu-panel hidden-web" alt="image">
                        <p class="mt-4 justificado" style="font-size: 16px; color: #585354;">
                        Las empresas gestionan sus trámites de una manera ágil
                        e intuitiva desde el panel de gestión, centralizando toda la
                        operatoria, estadísticas y validaciones desde un sólo lugar.
                    </p>
                    <p class="mt-4 justificado" style="font-size: 18px; color: #c00d0d; font-weight: bold;">
                        Ideal para empresas del mercado automotor,
                        estudios contables y legales, entidades
                        financieras, etc.
                    </p>
                    <a href="https://wa.me/59165057676" class="btn2 btn-nosotros mt-4" style="border-radius: 10px !important">Solicitar Información</a>
                </div>
                
                
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start App Funfacts Area -->
<!-- <section class="app-funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-download"></i>
                    </div>
                    <h3><span class="count">1.5</span>B</h3>
                    <p>Download</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-star"></i>
                    </div>
                    <h3><span class="count">4.8</span></h3>
                    <p>Rating</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-user-suited"></i>
                    </div>
                    <h3><span class="count">500</span>M</h3>
                    <p>Active User</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-thumbs-up"></i>
                    </div>
                    <h3><span class="count">13</span>K</h3>
                    <p>Updates</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End App Funfacts Area -->

<!-- Start SAAS Tools Area -->
<!-- <section class="saas-tools ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Tools for Everyone</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="tab">
                    <ul class="tabs">
                        <li><a href="#">
                            <i class="icofont-dashboard-web"></i>
                            <br>
                            Dashboard
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-inbox"></i>
                            <br>
                            Inbox
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-ui-calendar"></i>
                            <br>
                            Calendar
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-copy-invert"></i>
                            <br>
                            Invoicing
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-wrench"></i>
                            <br>
                            Reporting
                        </a></li>
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Dashboard</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Inbox</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Calendar</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Invoicing</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Reporting</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End SAAS Tools Area -->

<!-- Start Screenshot Area -->
<!-- <section class="screenshot-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Screenshot</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="screenshot-contain">
                    <img src="assets/img/mobile.png" class="mobile-light" alt="mobile">

                    <div class="row">
                        <div class="screenshot-slides owl-carousel owl-theme">
                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img1.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img4.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img2.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img3.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img4.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img1.png" alt="screenshot">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="screenshot-item">
                                    <img src="assets/img/screenshot-img2.png" alt="screenshot">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Screenshot Area -->

<!-- Start Team Area -->
<!-- <section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Our Smart Team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="team-slides owl-carousel owl-theme">
            <div class="single-team-member">
                <img src="assets/img/team-img1.jpg" alt="team">

                <div class="team-content">
                    <h3>John Doe</h3>
                    <span>CEO</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member">
                <img src="assets/img/team-img2.jpg" alt="team">

                <div class="team-content">
                    <h3>Steven Smith</h3>
                    <span>Developer</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member">
                <img src="assets/img/team-img3.jpg" alt="team">

                <div class="team-content">
                    <h3>David Luiz</h3>
                    <span>Designer</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member">
                <img src="assets/img/team-img4.jpg" alt="team">

                <div class="team-content">
                    <h3>Park Tim</h3>
                    <span>SEO Expert</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="single-team-member">
                <img src="assets/img/team-img5.jpg" alt="team">

                <div class="team-content">
                    <h3>Doglas Costa</h3>
                    <span>Manager</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Team Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 style="color: #f1f5f7;">Hacemos tu vida más facil</h2>
            <!-- <div class="bar"></div> -->
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title" style="color: #585354; text-align: center;">Nuestra Misión</h3>
                    </div>

                    <div class="price-body">
                        <p style="color: #585354; text-align: center;" class="valores-font"> 
                            Otorgar el mejor servicio de gestión de <br>
                            trámites del mercado, brindando <br>
                            seguridad y confianza a nuestros clientes <br>
                            desarrollando servicios de calidad con <br>
                            sòlidos principios éticos, con niveles altos <br>
                            de confianza y seguridad.
                        </p>
                    </div>
                </div>
                
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title" style="color: #585354; text-align: center;">Nuestra Visión</h3>
                    </div>

                    <div class="price-body">
                        <p style="text-align: center;" class="valores-font"> 
                            Ser la empresa líder referente del mercado <br>
                            boliviano, pionera en servicios de gestión de <br>
                            trámites desarrollando innovación constante <br>
                            con procesos claros que satisfagan las <br>
                            necesidades de nuestres clientes, apoyados <br>
                            en personal altamente calificado y <br>
                            capacitado.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table card-valores" style="background: #f1f5f7 url('assets/img/valores.png') no-repeat bottom 0px center/ auto;">
                    <div class="price-header">
                        <h3 class="title" style="color: #585354; text-align: center;">Valores</h3>
                    </div>

                    <div class="price-body">
                        <ul> 
                            <li class="li-valores">Transparencia</li>
                            <li class="li-valores">Honestidad</li>
                            <li class="li-valores">Calidad en servicios</li>
                            <li class="li-valores">Responsabilidad</li>
                            <li class="li-valores">Seriedad</li>
                            <li class="li-valores">Idoneidad</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section>
<!-- End Pricing Area -->

<!-- Start App contactanos Area -->
<section id="contacto" class="ptb-100 container-fondo" style="background-color: #f1f5f7;">
    <div class="container">
        <div class="row">
            <div class="section-title">
                <h2 style="color: #c00d0d;">Contactanos</h2>
                <p class="mt-2">Nuestro equipo se comunicará a la brevedad</p>
            </div>
        </div>

        <div class="overview-box">
            <div class="row mb-4 h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <form (ngSubmit)="save()" [formGroup]="formContacto">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="name" placeholder="Nombre">
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" formControlName="email" placeholder="E-mail">
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="phone" placeholder="Teléfono">
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="subject" placeholder="Asunto">
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" formControlName="message" id="message" cols="30" rows="5" placeholder="Mensaje"></textarea>
                                </div>
                            </div>
    
                            <div class="col-lg-12 col-md-12 alineacion-contacto">
                                <button type="submit" class="btn2 btn-nosotros" style="border-radius: 10px !important; padding: 13px 40px;">Enviar</button>
                            </div>
                            <img src="assets/img/personaje-contacto.png" alt="" class="celutramite">
                        </div>
                    </form>
                </div>
                
                <div class="col-lg-6 col-md-6 overview-content contacto map-contact"style="padding-left: 20px; padding-right: 20px;">
                    <ul class="lista-contacto pt-4">
                        <li>
                            <a href="https://wa.me/59165057676" style="color: #195c7f; font-weight: bold;"><i class="icofont-phone" style="color: black;"></i> +59165057676</a>
                        </li>
                        <li>
                            <!-- <a href="https://goo.gl/maps/GUvu3h6MhasedGPp8" style="color: #195c7f; font-weight: bold;"><i class="icofont-google-map" style="color: black;"></i> Av. Alemana, c/Sumuque #2335</a> -->
                            <a href="#" style="color: #195c7f; font-weight: bold;"><i class="icofont-google-map" style="color: black;"></i> Barrio Las Palmas Calle Tobite #12</a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/TuTramiteYaBo/" target="_blank" style="color: #195c7f; font-weight: bold;"><i class="icofont-facebook" style="color: black;"></i> facebook.com/TuTramiteYaBo</a>
                        </li>
                    </ul>

                    <div class="pl-4 padd-map">
                        <!-- width="350" height="300"  -->
                        <!-- <iframe 
                            [src]='sanitizer.bypassSecurityTrustResourceUrl(mapsURL)' 
                            frameborder="0" 
                            allowfullscreen webkitallowfullscreen mozallowfullscreen>
                        </iframe> -->
                    </div>
                    
                </div>
                
                
            </div>
        </div>
    </div>

    <!-- <img src="assets/img/celutramiteya.png" alt="image" style="position: relative; bottom: 0; left: 0; display: flex; width: 300px;"> -->

</section>
<!-- End contactanos Area -->

<!-- <app-footer></app-footer> -->
<!-- Start App Download Area -->
<!-- <section class="app-download ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <h2>Download Our Apps Today</h2>
                    <div class="bar"></div>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account the system and expound the actual teachings of the great explore.</p>
                    <div class="download-btn">
                        <a href="#" target="_blank"><i class="icofont-brand-android-robot"></i> Available On <span>Google Store</span></a>
                        <a href="#" target="_blank"><i class="icofont-brand-apple"></i> Available On <span>Apple Store</span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="download-image">
                    <img src="assets/img/app-img1.png" alt="image">
                    <img src="assets/img/app-img2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End App Download Area -->

<!-- Start Blog Area -->
<!-- <section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 23, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Business Apps</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 16, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Best Marketing Management Tools</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 14, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">3 WooCommerce Plugins to Boost Sales</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 06, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">CakeMail Review – Design Custom Emails</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 04, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Most Popular New Apps in 2019</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>

            <div class="single-blog-post">
                <a routerLink="/blog-details" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                <div class="blog-post-content">
                    <ul>
                        <li><i class="icofont-user-male"></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class="icofont-wall-clock"></i> January 26, 2019</li>
                    </ul>
                    <h3><a routerLink="/blog-details">The Fastest Growing Apps in 2019</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Blog Area -->

<!-- Start Feedback Area -->
<!-- <section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client1.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client2.jpg" alt="client">
                    <h3>Alit John</h3>
                    <span>SEO Expert</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client3.jpg" alt="client">
                    <h3>Steven John</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>

            <div class="single-feedback">
                <div class="client-info">
                    <img src="assets/img/client4.jpg" alt="client">
                    <h3>David Warner</h3>
                    <span>Web Developer</span>
                </div>
                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section> -->
<!-- End Feedback Area -->

<!-- Start Subscribe Area -->
<!-- <section class="subscribe-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Connect With Our Community</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <form class="newsletter-form">
            <input type="email" class="form-control" placeholder="Enter your email address" name="email" required autocomplete="off">
            <button class="btn btn-primary" type="submit">Subscribe</button>
        </form>
    </div>
</section> -->
<!-- End Subscribe Area -->