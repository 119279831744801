import { Injectable } from '@angular/core';
import { environment             } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { stringify               } from 'qs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http: HttpClient,
  ) { }

  public getApiUrl(): string {
    return environment.serverApiUrl;
  }

  public get(url:string, data?:any){
    
    let params = stringify(data);
    
    return this.http.get(environment.serverApiUrl + url + params)
  }

  public post(url:string, data){
    return this.http.post(environment.serverApiUrl + url, data)
  }


}
